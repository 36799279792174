<template>
  <div>
    <v-parallax
      dark
      :src="imgSrc"
      height="400"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
          >
            <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h2' : 'h3']">
              THE CHILDREN
            </h1>
            <v-container class="py-0">
              <hr>
            </v-container>
          </v-col>
          <v-col
            class="text-center"
            cols="12"
          >
            <v-container>
              <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h35' : 'h5']">
                Hope and Angels Foundation is presently supporting children in three different locations of Angola (Mupa, Luanda (Fubu) and Ondjiva).
              </h1>
            </v-container>
          </v-col>
        </v-row>
      </v-overlay>
    </v-parallax>
    <v-card rounded="0" color="grey darken-3">
      <v-tabs v-model="tab" right background-color="grey darken-3" color="white">
        <v-tab href="#tab-Children" style="color:white">
          Overview
        </v-tab>
        <v-tab href="#tab-Mupa" style="color:white">
          Mupa & Cunene Province
        </v-tab>
        <v-tab href="#tab-Luanda" style="color:white">
          Daily Community Program in Luanda
        </v-tab>
        <v-tab href="#tab-Ondjiva" style="color:white">
          Ondjiva Project
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-Children">
          <v-card flat rounded="0">
            <v-card-text>
              <ChildrenContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-Mupa">
          <v-card flat rounded="0">
            <v-card-text>
              <MupaContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-Luanda">
          <v-card flat rounded="0">
            <v-card-text>
              <LuandaContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-Ondjiva">
          <v-card flat rounded="0">
            <v-card-text>
              <OndjivaContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ChildrenContent from '@/components/hope/ChildrenContent.vue'
import MupaContent from '@/components/hope/MupaContent.vue'
import LuandaContent from '@/components/hope/LuandaContent.vue'
import OndjivaContent from '@/components/hope/OndjivaContent.vue'

export default {
  components: {
    ChildrenContent,
    LuandaContent,
    MupaContent,
    OndjivaContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide2.jpg')
    }
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Children'
      }
    }
  }
}
</script>
